import { config, normalize } from "@scc-kk/normalize-japanese-addresses";

// 住所正規化ライブラリの初期化
if (import.meta.env.VITE_JAPANESE_ADDRESSES) {
  config.japaneseAddressesApi = import.meta.env.VITE_JAPANESE_ADDRESSES;
}

const addressUtils = {
  /**
   * 都道府県を除いた住所を返します。
   * @param {string} address 住所
   * @returns {string} 都道府県を除いた住所
   */
  trimPrefecture(address) {
    return address.replace(/^.{2,3}[都道府県]/, "");
  },

  /**
   * 2つの住所を半角スペース付きで結合して返す。
   * @param {string} address1
   * @param {string} address2
   * @returns {string}
   */
  joinWithSpace(address1, address2) {
    return address2 ? address1 + " " + address2 : address1;
  },

  /**
   * Geoloniaの住所正規化ライブラリで住所を正規化する。
   * @param {string} address 住所
   * @returns {Promise<import("@scc-kk/normalize-japanese-addresses").NormalizeResult>} 正規化済の住所
   */
  async normalizeAddress(address) {
    return await normalize(address);
  },

  /**
   * Googleマップへのリンク用に住所を正規化する。
   * @param {string} address 住所
   * @returns {Promise<string>} 正規化済の住所
   */
  async normalizeAddressForGoogleMap(address) {
    let normalizedAddress = address;
    try {
      const nr = await normalize(address);
      if (nr.level == 8) {
        let other;
        if (nr.other) {
          other = nr.other.replace(/^(-\d+)(?:-\d+)*\s*号?室?\s*/, "$1 ");
          const firstSpaceIndex = other.indexOf(" ");
          if (firstSpaceIndex != -1) {
            other =
              other.substring(0, firstSpaceIndex + 1) +
              other
                .substring(firstSpaceIndex + 1)
                .replace(/\s*(?:株式|有限|合同|合資|合名)会社.+$/, "")
                .replace(/\s*\d+号?室?$/, "")
                .replace(/\s*\d+[階Ff]$/, "");
          }
          other = other.trim();
        }
        normalizedAddress =
          (nr.pref ?? "") +
          (nr.city ?? "") +
          (nr.town ?? "") +
          (nr.addr ?? "") +
          (other ?? "");
      }
    } catch (error) {
      console.warn(error);
    }
    return normalizedAddress;
  },
};

export default Object.freeze(addressUtils);
