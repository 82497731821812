import addressUtils from "~/libs/addressUtils";

/**
 * geoloniaの住所正規化ライブラリを利用して、住所の緯度・経度を照合します。
 * https://github.com/geolonia/normalize-japanese-addresses
 * @param {string} address
 * @returns {Promise<{latitude: number, longitude: number}>} 値がある場合、緯度と経度のオブジェクト。値がない場合はerror。
 */
export async function parseAddress(address) {
  const result = await addressUtils.normalizeAddress(address);
  console.log(`${address} ${JSON.stringify(result)}`);
  if (typeof result.lat !== "number" || typeof result.lng !== "number") {
    throw new Error(
      "Latitude and longitude could not be calculated: " + address,
    );
  }
  return {
    latitude: result.lat,
    longitude: result.lng,
  };
}

/**
 * ある緯度・経度を原点として、ターゲットの緯度・経度をメルカトル図法で座標に変換。
 *
 * @param {number} lat_1 基準の緯度
 * @param {number} lon_1 基準の経度
 * @param {number} lat_2 ターゲットの緯度
 * @param {number} lon_2 ターゲットの経度
 * @returns {{x: number, y: number}} 座標のオブジェクト
 */
export function latlon2coordinate(lat_1, lon_1, lat_2, lon_2) {
  // TODO ZOOMレベル固定しているが、一番遠い座標を基準にZOOMレベルを動的に変えるようにしたい
  const ZOOM = Math.pow(2, 4);
  const R = 6371;
  const dlat = degree2radian(lat_2 - lat_1);
  const dlon = degree2radian(lon_2 - lon_1);

  const x = R * dlon * ZOOM;
  const y = R * Math.log(Math.tan(Math.PI / 4 + dlat / 2)) * ZOOM;

  return { x, y };
}

/**
 * ２点間の直線距離を「HaverSine formular」で計算。
 * @param {number} lat_1 緯度１
 * @param {number} lon_1 経度１
 * @param {number} lat_2 緯度２
 * @param {number} lon_2 経度２
 * @returns {number} 直線距離（m）
 */
export function calcDistance(lat_1, lon_1, lat_2, lon_2) {
  const R = 6371;
  const dlat = degree2radian(lat_2 - lat_1);
  const dlon = degree2radian(lon_2 - lon_1);

  const a =
    Math.sin(dlat / 2) * Math.sin(dlat / 2) +
    Math.cos(degree2radian(lat_1)) *
      Math.cos(degree2radian(lat_2)) *
      Math.sin(dlon / 2) *
      Math.sin(dlon / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c * 1000;
  return d;
}

/**
 * Bearingで2点間の方位角を計算します。
 * @param {number} slat 緯度（始点）
 * @param {number} slon 経度（始点）
 * @param {number} dlat 緯度（終点）
 * @param {number} dlon 経度（終点）
 * @returns {{bearing: number, label: string}} 方位角
 */
export function calcBearing(slat, slon, dlat, dlon) {
  const cardinalDirections = [
    { start: 0, end: 1125, label: "北" },
    { start: 1125, end: 3375, label: "北北東" },
    { start: 3375, end: 5625, label: "北東" },
    { start: 5625, end: 7875, label: "東北東" },
    { start: 7875, end: 10125, label: "東" },
    { start: 10125, end: 12375, label: "東南東" },
    { start: 12375, end: 14625, label: "南東" },
    { start: 14625, end: 16875, label: "南南東" },
    { start: 16875, end: 19125, label: "南" },
    { start: 19125, end: 21375, label: "南南西" },
    { start: 21375, end: 23625, label: "南西" },
    { start: 23625, end: 25875, label: "西南西" },
    { start: 25875, end: 28125, label: "西" },
    { start: 28125, end: 30375, label: "西北西" },
    { start: 30375, end: 32625, label: "北西" },
    { start: 32625, end: 34875, label: "北北西" },
    { start: 34875, end: 36000, label: "北" },
  ];

  const radSLat = degree2radian(slat);
  const radSLon = degree2radian(slon);
  const radDLat = degree2radian(dlat);
  const radDLon = degree2radian(dlon);

  const y = Math.sin(radDLon - radSLon) * Math.cos(radDLat);
  const x =
    Math.cos(radSLat) * Math.sin(radDLat) -
    Math.sin(radSLat) * Math.cos(radDLat) * Math.cos(radDLon - radSLon);

  const bearing = (radian2degree(Math.atan2(y, x)) + 360) % 360;
  const bearingHundredfold = Math.round(bearing * 100);

  return {
    bearing: bearing,
    label: cardinalDirections.find(
      (d) => bearingHundredfold >= d.start && bearingHundredfold < d.end,
    ).label,
  };
}

/**
 * 度数法の値を弧度法（ラジアン）の値に変換。
 * @param {number} degree 度数法の値
 * @returns {number} 弧度法の値
 */
export function degree2radian(degree) {
  return degree * (Math.PI / 180);
}

/**
 * 弧度法（ラジアン）の値を度数法の値に変換。
 * @param {number} radian 弧度法の値
 * @returns {number} 度数法の値
 */
export function radian2degree(radian) {
  return (radian * 180) / Math.PI;
}

/**
 * メートル単位の距離を表示用にフォーマットする。
 * <p>
 *   1km未満：m単位/小数点なし (例：950m)
 *  10km未満：km単位/小数点第2位 (例：9.85km)
 * 100km未満：km単位/小数点第1位 (例：39.8km)
 * 100km以上：km単位/小数点なし (例：101km)
 * @param {number} distanceInMeters メートル単位の距離
 * @param {boolean} [ceils50Meters=true] 距離を50m単位で切り上げるか否か
 * @returns {string} フォーマット後の距離
 */
export function formatDistanceInMeters(distanceInMeters, ceils50Meters = true) {
  const v = ceils50Meters
    ? (Math.ceil((distanceInMeters * 2) / 100) * 100) / 2
    : Math.ceil(distanceInMeters);
  if (v < 1000) {
    return v + "m";
  } else if (v < 10_000) {
    return Math.floor(v / 10) / 100 + "km";
  } else if (v < 100_000) {
    return Math.round(v / 100) / 10 + "km";
  } else {
    return Math.round(v / 1000) + "km";
  }
}
