<script>
  import { format as formatDate } from "date-fns";
  import { ja as localeJa } from "date-fns/locale";
  import { _ } from "svelte-i18n";

  import {
    NO_PICKUP_TIMEFRAME,
    RedeliveryDateSpecifyMethod,
  } from "~/libs/constants";

  export let data;

  /**
   * 初回配達のとき、荷物を届ける指定日時をフォーマットして表示する
   * @param {string} desiredDate - 希望日
   * @param {string} desiredTime - 希望時間
   * @returns {string} フォーマットされた希望日時
   */
  function formattedDesiredTime(desiredDate, desiredTime) {
    if (!desiredDate) {
      // 希望日が指定されていない場合
      if (!desiredTime) {
        // 希望時間が指定されていない場合
        return "なし";
      } else {
        // 希望時間が指定されている場合
        return `${desiredTime.slice(0, 2).replace(/^0/, "")}～${desiredTime
          .slice(2, 4)
          .replace(/^0/, "")}時`;
      }
    } else if (isBeforeToday(desiredDate)) {
      // 希望日が本日以前の場合
      if (!desiredTime) {
        // 希望時間が指定されていない場合
        return formatDate(desiredDate, "M/d(E)", {
          locale: localeJa,
        });
      } else {
        // 希望時間が指定されている場合
        return (
          formatDate(desiredDate, "M/d(E)", {
            locale: localeJa,
          }) +
          " " +
          `${desiredTime.slice(0, 2).replace(/^0/, "")}～${desiredTime
            .slice(2, 4)
            .replace(/^0/, "")}時`
        );
      }
    } else {
      // 希望日が翌日以降の場合
      return formatDate(desiredDate, "M/d(E)", {
        locale: localeJa,
      });
    }
  }

  /**
   * 希望日が本日以前かどうかを判定する。
   * @param {string} date
   * @returns {boolean} 希望日が本日以前の場合はtrue、それ以外はfalse
   */
  function isBeforeToday(date) {
    if (date) {
      const now = new Date();
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      // 日付のみの比較を行う (時間は無視)
      return new Date(date).setHours(0, 0, 0, 0) <= today.setHours(0, 0, 0, 0);
    } else {
      return false;
    }
  }

  /**
   * 1週間分の再配達受取可能時間帯が全て「受け取り不可」の場合にtrue
   * 1週間分の再配達受け取り可能時間帯が未設定、また再配達希望日時を1つ指定してもらった場合もtrueになるが、その場合は用途無し
   * @param {object} data // TODO: 型を定義
   * @returns {boolean}
   */
  function confirmNoAvailablePickupDateOfWeek(data) {
    let noAvailablePickupDateOfWeek = true;
    if (
      data.specifiedPickupDatetime &&
      data.specifiedPickupDatetime["availablePickupDatetime"]
    ) {
      data.specifiedPickupDatetime["availablePickupDatetime"].forEach(
        (element) => {
          if (element["timeFrame"] !== NO_PICKUP_TIMEFRAME) {
            noAvailablePickupDateOfWeek = false;
          }
        },
      );
    }
    return noAvailablePickupDateOfWeek;
  }
</script>

<div class="PackageInformationTitle">
  {#if data.address === "取得失敗"}
    データの取得に失敗しました
  {:else}
    <table class="deliveryNoticeTable">
      {#if data.receiverName}
        <tr>
          <th>荷受人名</th>
          <td>{data.receiverName}</td>
        </tr>
      {/if}
      {#if Number.isInteger(data.cashOnDeliveryAmount)}
        <tr>
          <th> 代引き指定 </th>
          <td>{data.cashOnDeliveryAmount.toLocaleString()}円</td>
        </tr>
      {/if}
      {#if data.desiredDate || data.desiredTime}
        <tr>
          <th>初回指定日時</th>
          <td>
            <!-- 内部で設定した文言しか埋め込まれないためエスケープ処理不要 -->
            {@html formattedDesiredTime(data.desiredDate, data.desiredTime)}
          </td>
        </tr>
      {/if}
      {#if data.redeliveryContext}
        <tr>
          <th>再配達希望日時</th>
          <td>
            {#if data.redeliveryContext["adjustedRedeliveryDatetime"]}
              <!-- 電話で調整済みの場合 -->
              {#if new Date(data.redeliveryContext["adjustedRedeliveryDatetime"]["date"]).toDateString() === new Date().toDateString()}本日
              {/if}
              {formatDate(
                new Date(
                  data.redeliveryContext["adjustedRedeliveryDatetime"]["date"],
                ),
                "M/d(E)",
                { locale: localeJa },
              )}
              {Number(
                data.redeliveryContext["adjustedRedeliveryDatetime"][
                  "timeFrame"
                ].substring(0, 2),
              )}～{Number(
                data.redeliveryContext["adjustedRedeliveryDatetime"][
                  "timeFrame"
                ].substring(2, 4),
              )}時
            {:else if data.redeliveryContext["redeliveryDatetimeSpecMethod"] === RedeliveryDateSpecifyMethod.DESIRED_DATE_AND_TIME && data.specifiedPickupDatetime && data.specifiedPickupDatetime["desiredRedeliveryDatetime"]}
              <!-- 希望日時を1つ指定してもらうよう依頼し、荷受人による設定済み -->
              {#if data.specifiedPickupDatetime["desiredRedeliveryDatetime"]["timeFrame"] === NO_PICKUP_TIMEFRAME}
                <!-- 受け取れる日時無し -->
                {formatDate(
                  new Date(
                    data.specifiedPickupDatetime["desiredRedeliveryDatetime"][
                      "date"
                    ],
                  ),
                  "M/d(E)",
                  { locale: localeJa },
                )}まで受け取り可能時間帯なし
              {:else}
                <!-- 受け取れる日時あり -->
                {#if new Date(data.specifiedPickupDatetime["desiredRedeliveryDatetime"]["date"]).toDateString() === new Date().toDateString()}本日
                {/if}
                {formatDate(
                  new Date(
                    data.specifiedPickupDatetime["desiredRedeliveryDatetime"][
                      "date"
                    ],
                  ),
                  "M/d(E)",
                  { locale: localeJa },
                )}
                {Number(
                  data.specifiedPickupDatetime["desiredRedeliveryDatetime"][
                    "timeFrame"
                  ].substring(0, 2),
                )}～{Number(
                  data.specifiedPickupDatetime["desiredRedeliveryDatetime"][
                    "timeFrame"
                  ].substring(2, 4),
                )}時
              {/if}
            {:else if data.redeliveryContext["redeliveryDatetimeSpecMethod"] === RedeliveryDateSpecifyMethod.AVAILABLE_RECEIVE_TIME_ZONE_OF_WEEK && data.specifiedPickupDatetime && data.specifiedPickupDatetime["availablePickupDatetime"]}
              <!-- 一週間分の受け取り可能時間帯を設定してもらう依頼し、荷受人による設定済み -->
              {#if confirmNoAvailablePickupDateOfWeek(data)}
                <!-- 全日「受け取り不可」 -->
                {formatDate(
                  new Date(
                    data.specifiedPickupDatetime["availablePickupDatetime"][
                      data.specifiedPickupDatetime["availablePickupDatetime"]
                        .length - 1
                    ]["date"],
                  ),
                  "M/d(E)",
                  { locale: localeJa },
                )}まで受け取り可能時間帯なし
              {:else}
                <!-- 受け取り可能な日がある -->
                {#each data.specifiedPickupDatetime["availablePickupDatetime"] as datetime, i}
                  {formatDate(new Date(datetime["date"]), "M/d(E)", {
                    locale: localeJa,
                  })}
                  {#if datetime["timeFrame"] === NO_PICKUP_TIMEFRAME}
                    受け取り不可
                  {:else}
                    {Number(datetime["timeFrame"].substring(0, 2))}時以降
                  {/if}
                  {#if i !== data.specifiedPickupDatetime["availablePickupDatetime"].length - 1}
                    、
                  {/if}
                {/each}
              {/if}
            {:else}
              設定依頼中
            {/if}</td
          >
        </tr>
      {/if}
      {#if Number.isInteger(data.packageDropPlace)}
        <tr>
          <th>受渡方法</th>
          <td>
            {$_("classes.deliveryMethod." + data.packageDropPlace)}
          </td>
        </tr>
      {/if}
      {#if data.extraEvent != null}
        <tr>
          <th>配達履歴</th>
          <td>
            {#each data.extraEvent as event}
              <div class="deliveryHistory">
                <div class="dateAndTime">
                  {formatDate(new Date(event.time), "M/d(E) H:mm", {
                    locale: localeJa,
                  })}
                </div>
                <div class="history">
                  {$_(
                    "pages.Update.extraEventTypeLabel." + event.extraEventType,
                  )}
                </div>
              </div>
            {/each}</td
          >
        </tr>
      {/if}
    </table>
  {/if}
</div>

<style lang="scss">
  .PackageInformationTitle {
    border-radius: 2px;
    color: #333;
    font-size: 15px;
    line-height: 1.6;
    padding: 10px;
    text-align: left;
  }

  .deliveryNoticeTable {
    border-top: 1px solid #333;
    border-left: 1px solid #333;
    width: 100%;

    th {
      background-color: #2ab47410;
      border-right: 1px solid #333;
      border-bottom: 1px solid #333;
      width: 110px;
      padding: 5px 8px;
    }
    td {
      border-right: 1px solid #333;
      border-bottom: 1px solid #333;
      padding: 5px 8px;
    }
  }

  .deliveryHistory:not(:first-of-type) {
    margin-top: 5px;
  }

  .deliveryHistory .dateAndTime {
    color: #018786;
    font-size: 0.9em;
    font-weight: bold;
  }
</style>
