import {
  ZBarConfigType,
  ZBarSymbolType,
  getDefaultScanner,
  scanImageData,
  setModuleArgs,
} from "@undecaf/zbar-wasm";
import zbarWasmBinary from "@undecaf/zbar-wasm/dist/zbar.wasm?url";
import { Html5QrcodeShim } from "html5-qrcode/esm/code-decoder.js";
import {
  BaseLoggger,
  Html5QrcodeSupportedFormats,
} from "html5-qrcode/esm/core.js";
import UAParser from "ua-parser-js";

/** 適切な背面カメラがデフォルトで選択されるかをチェックするか否か */
let checksDefaultBackCamera = true;

/**
 * @callback onScanSuccessCallback
 * @param {string} decodedText
 * @param {boolean} needsDecode
 * @param {CodeType} codeType
 *
 * @callback onScanErrorCallback
 * @param {string} errorMessage
 * @param {Error|?} error
 *
 * @callback onReadQrCodeCallback
 * @param {ImageData} [imageData]
 *
 * @callback onPauseStatusChangeCallback
 * @param {boolean} currentlyPaused
 *
 * @callback onVideoResizedCallback
 */

/**
 * コード種別
 * @enum {1 | 2}
 */
export const CodeType = Object.freeze({
  QRCODE: 1,
  CODABAR: 2,
});

export class QrCodeScanner {
  /** @type {onScanSuccessCallback} */
  onScanSuccessHandler;
  /** @type {onScanErrorCallback} */
  onScanErrorHandler;
  /** @type {onPauseStatusChangeCallback} */
  #onPauseStatusChangeHandler;
  /** @type {onVideoResizedCallback} */
  #onVideoResizedHandler;
  /** @type {onReadQrCodeCallback} */
  onReadQrcode;

  /** @type {HTMLVideoElement} */
  video;
  /** @type {HTMLDivElement} */
  qrCodeScannerArea;
  /** @type {HTMLDivElement} */
  previewRegion;
  /** @type {HTMLDivElement} */
  shadedRegion;
  /** @type {HTMLCanvasElement} */
  qrCodeCanvasElement;
  /** @type {HTMLDivElement} */
  qrCodeScannerFallbackArea;
  /** @type {HTMLDivElement} */
  retryRecommendedArea;

  /** @type {CanvasRenderingContext2D} */
  qrCodeCanvas;

  /** @type {boolean} */
  #paused = false;
  get paused() {
    return this.#paused;
  }
  set paused(value) {
    this.#paused = value;
    this.#onPauseStatusChangeHandler(value);
  }

  /** @type {boolean} */
  #scanInProgress = false;

  /** @type {boolean} */
  #torched = false;
  /** @type {boolean} */
  #torchSupported = false;
  /** @type {{lastChecked: number, isDarkCanvas: Array<boolean>, filter: {enabled: boolean, power?: number}}} */
  #canvasCorrectionStatus = {
    lastChecked: 0,
    isDarkCanvas: [],
    filter: { enabled: false },
  };

  /** QRコードのスキャン間隔（FPS） */
  #frameRate = 10;

  /**
   * @type {{windowOuterWidth?: number, windowOuterHeight?: number, videoWidth?: number, videoHeight?: number}}
   */
  #currentSizeContext = {};

  /**
   * @param {onScanSuccessCallback} onScanSuccessHandler
   * @param {onScanErrorCallback} onScanErrorHandler
   * @param {onPauseStatusChangeCallback} onPauseStatusChangeHandler
   * @param {onVideoResizedCallback} onVideoResizedHandler
   */
  constructor(
    onScanSuccessHandler,
    onScanErrorHandler,
    onPauseStatusChangeHandler,
    onVideoResizedHandler,
  ) {
    this.onScanSuccessHandler = onScanSuccessHandler;
    this.onScanErrorHandler = onScanErrorHandler;
    this.#onPauseStatusChangeHandler = onPauseStatusChangeHandler;
    this.#onVideoResizedHandler = onVideoResizedHandler;
  }

  /**
   * @param {import("~/libs/commonTypes").AppContext} appContext
   * @param {boolean} [useBackCamera=true]
   * @param {() => void} [startedCallback] スキャン開始後に呼び出すコールバック関数
   */
  async startScanning(
    appContext,
    useBackCamera = true,
    startedCallback = undefined,
  ) {
    if (
      !this.video ||
      !this.qrCodeScannerArea ||
      !this.previewRegion ||
      !this.shadedRegion ||
      !this.qrCodeCanvasElement
    ) {
      throw new Error(
        `Not initialized: ${this.video}, ${this.qrCodeScannerArea}, ${this.previewRegion}` +
          `, ${this.shadedRegion}, ${this.qrCodeCanvasElement}`,
      );
    }

    if (this.#scanInProgress) {
      console.error("QR code scanning has already started"); // use non-logger explicitly
      return;
    }
    this.#scanInProgress = true;

    try {
      /** @type {MediaStreamConstraints} */
      const mediaStreamConstraints = {
        video: {
          facingMode: useBackCamera ? "environment" : "user",
          frameRate: {
            ideal: this.#frameRate,
          },
        },
      };
      const videoConstraints = /** @type {MediaTrackConstraints} */ (
        mediaStreamConstraints.video
      );

      videoConstraints.width = {
        ideal: 2048,
      };
      videoConstraints.height = {
        ideal: 2048,
      };

      if (appContext.useManualCameraSelection === true) {
        if (useBackCamera && appContext.selectedBackCameraId) {
          videoConstraints.deviceId = appContext.selectedBackCameraId;
        } else if (!useBackCamera && appContext.selectedFrontCameraId) {
          videoConstraints.deviceId = appContext.selectedFrontCameraId;
        }
      }

      console.log("Start Scanning:", mediaStreamConstraints);
      let mediaStream = await navigator.mediaDevices.getUserMedia(
        mediaStreamConstraints,
      );

      try {
        if (
          checksDefaultBackCamera &&
          useBackCamera &&
          !appContext.selectedBackCameraId &&
          UAParser(navigator.userAgent)?.os?.name === "Android"
        ) {
          // 背面カメラの一覧を取得（labelの辞書順にソート）
          const backCameras = (await navigator.mediaDevices.enumerateDevices())
            .filter(
              (device) =>
                device.kind === "videoinput" &&
                device.label.toLowerCase().indexOf("前面") == -1 &&
                device.label.toLowerCase().indexOf("front") == -1,
            )
            .sort((lhs, rhs) => lhs.label.localeCompare(rhs.label));

          /** @type {MediaTrackSettings} */
          let currentSettings;
          if (backCameras.length >= 2) {
            currentSettings = mediaStream.getVideoTracks()[0]?.getSettings?.();
            if (
              currentSettings &&
              backCameras[0].deviceId !== currentSettings.deviceId
            ) {
              // 背面カメラが2種類以上あり、背面カメラ一覧の最初のカメラが現在使用中のカメラと異なる場合は、最初のカメラへ切替
              appContext.useManualCameraSelection = true;
              videoConstraints.deviceId = appContext.selectedBackCameraId =
                backCameras[0].deviceId;
              appContext.store();

              // 新しいカメラで開始し直す
              mediaStream.getTracks().forEach((track) => {
                track.stop();
              });
              mediaStream = await navigator.mediaDevices.getUserMedia(
                mediaStreamConstraints,
              );
            }
          }
          console.log("背面カメラ情報:", backCameras, currentSettings);
        }
      } finally {
        checksDefaultBackCamera = false;
      }

      const supportedConstraints =
        navigator.mediaDevices.getSupportedConstraints();
      // @ts-ignore
      this.#torchSupported = supportedConstraints.torch === true;

      // 鏡映反転の補正を実施
      if (useBackCamera) {
        this.video.style.transform = null;
      } else {
        this.video.style.transform = "rotateY(180deg)";
      }

      this.#adjustMaxVideoHeight();

      this.video.srcObject = mediaStream;

      // iOSのバグによりvideo.playが返ってこないことがあるため、非同期にしてユーザが戻る操作をできるようにする
      (async () => {
        setTimeout(() => {
          // 2秒待っても再生されない場合はリトライ推奨のメッセージを表示する
          if (
            this.#scanInProgress &&
            this.qrCodeScannerArea &&
            this.qrCodeScannerArea.style.display !== "block"
          ) {
            this.retryRecommendedArea.style.display = "block";
          }
        }, 2000);
        try {
          await this.video.play();
        } catch (error) {
          console.error("Error in video.play:", error);
        }
        if (
          this.retryRecommendedArea &&
          this.retryRecommendedArea.style.display === "block"
        ) {
          // 再生された場合はリトライ推奨のメッセージを非表示にする
          this.retryRecommendedArea.style.display = "none";
        }
        this.#requestNextFrame();
        if (this.qrCodeScannerArea) {
          this.qrCodeScannerArea.style.display = "block";
        }

        startedCallback?.();
      })();
    } catch (error) {
      if (error.name === "NotAllowedError") {
        console.log("Requested camera device cannot be used at this time.");
        this.qrCodeScannerFallbackArea.style.display = "block";
      } else {
        throw error;
      }
    }
  }

  async stopScanning() {
    if (!this.#scanInProgress) {
      return;
    }
    this.#scanInProgress = false;

    console.log("Stop Scanning");
    this.qrCodeScannerArea.style.display = "none";
    if (this.video.srcObject) {
      if (!(this.video.srcObject instanceof MediaStream)) {
        throw new TypeError(
          "video.srcObject is typeof " + typeof this.video.srcObject,
        );
      }
      this.video.srcObject.getTracks().forEach((track) => {
        track.stop();
      });
      this.video.srcObject = null;
    }

    // initialize instance fields
    this.#currentSizeContext = {};
    this.#torched = false;
    this.#canvasCorrectionStatus = {
      lastChecked: 0,
      isDarkCanvas: [],
      filter: { enabled: false },
    };
    this.paused = false;
  }

  /**
   * @param {boolean} [pause]
   */
  pauseOrResumeScanning(pause = undefined) {
    if (this.video?.srcObject instanceof MediaStream) {
      const trackEnabled = pause === undefined ? this.paused : !pause;
      this.video.srcObject.getTracks().forEach((track) => {
        track.enabled = trackEnabled;
      });
      this.paused = !trackEnabled;
      if (this.paused) {
        console.log("Pause Scanning");
      } else {
        console.log("Resume Scanning");
        this.#requestNextFrame();
      }
    }
  }

  resizeIfNeeded() {
    if (
      this.#currentSizeContext.windowOuterWidth !== window.outerWidth ||
      this.#currentSizeContext.windowOuterHeight !== window.outerHeight ||
      this.#currentSizeContext.videoWidth !== this.video.videoWidth ||
      this.#currentSizeContext.videoHeight !== this.video.videoHeight
    ) {
      // landscapeの場合は画面横幅（outerWidth）の1/2をカメラのプレビュー領域にする
      this.previewRegion.style.width =
        window.outerWidth > window.outerHeight
          ? `${Math.floor(window.outerWidth / 2)}px`
          : "100%";

      this.#adjustMaxVideoHeight();

      // ROIの外側の領域のサイズを調整：横（左1/5、右1/5）、縦（上1/4、下1/4）
      this.shadedRegion.style["border-width"] = `${Math.floor(
        this.previewRegion.clientHeight / 4,
      )}px ${Math.floor(this.previewRegion.clientWidth / 5)}px`;

      // コードをスキャンするcanvasのサイズ（ROI）をvideoサイズの横3/5、縦1/2に調整
      this.qrCodeCanvasElement.width = Math.ceil(
        (this.video.videoWidth / 5) * 3,
      );
      this.qrCodeCanvasElement.height = Math.ceil(
        this.#getTrimmedVideoHeight().height / 2,
      );

      this.#currentSizeContext = {
        windowOuterWidth: window.outerWidth,
        windowOuterHeight: window.outerHeight,
        videoWidth: this.video.videoWidth,
        videoHeight: this.video.videoHeight,
      };

      this.#onVideoResizedHandler();
    }
  }

  #adjustMaxVideoHeight() {
    // カメラ領域の最大高を画面縦幅（outerWidth）の3/7にする
    this.video.style.maxHeight = `${Math.floor(
      (window.outerHeight / 7) * 3,
    )}px`;
  }

  #tick() {
    if (!this.#scanInProgress || !this.video || this.paused) {
      return;
    }
    if (this.video.readyState === this.video.HAVE_ENOUGH_DATA) {
      this.resizeIfNeeded();

      const { height: videoHeight, offset: videoHeightOffset } =
        this.#getTrimmedVideoHeight();
      this.qrCodeCanvas.drawImage(
        this.video,
        Math.floor(this.video.videoWidth / 5), // video領域の左1/5地点
        videoHeightOffset + Math.floor(videoHeight / 4), // video領域の上1/4地点
        this.qrCodeCanvasElement.width, // video領域の3/5
        this.qrCodeCanvasElement.height, // video領域の1/2
        0,
        0,
        this.qrCodeCanvasElement.width,
        this.qrCodeCanvasElement.height,
      );

      const imageData = this.#correctDarkCanvas();
      this.onReadQrcode(imageData);
    }
    this.#requestNextFrame();
  }

  /**
   * @returns {{height: number, offset: number}}
   */
  #getTrimmedVideoHeight() {
    const trimmedVideoHeight = Math.floor(
      this.video.clientHeight *
        (this.video.videoWidth / this.video.clientWidth),
    );
    return {
      height: trimmedVideoHeight,
      offset: Math.floor((this.video.videoHeight - trimmedVideoHeight) / 2),
    };
  }

  #requestNextFrame() {
    setTimeout(() => this.#tick(), 1000 / this.#frameRate);
  }

  /**
   * @returns {ImageData}
   */
  #correctDarkCanvas() {
    if (this.#torched) {
      return null;
    }

    // check at least 500ms intervals
    const currentTimestamp = Date.now();
    if (currentTimestamp < this.#canvasCorrectionStatus.lastChecked + 500) {
      return null;
    }
    this.#canvasCorrectionStatus.lastChecked = currentTimestamp;

    // get canvas raw data
    const canvasImageData = this.qrCodeCanvas.getImageData(
      0,
      0,
      this.qrCodeCanvasElement.width,
      this.qrCodeCanvasElement.height,
    );
    const imageData = canvasImageData.data;

    // calculate dark pixel ratio
    let darkPixels = 0;
    let vAverage = 0;
    for (let i = 0; i < imageData.length; i += 4) {
      const r = imageData[i];
      const g = imageData[i + 1];
      const b = imageData[i + 2];
      const maxRGB = Math.max(r, g, b);
      vAverage += maxRGB;
      if (maxRGB < 128) {
        darkPixels++;
      }
    }
    vAverage /= imageData.length / 4;

    // check if the same brightness continues 3 times
    const darkPixelRatio = darkPixels / (imageData.length / 4);
    const isDarkCanvas = darkPixelRatio >= 0.95;
    const isDarkCanvasHistories = this.#canvasCorrectionStatus.isDarkCanvas;
    try {
      if (
        isDarkCanvasHistories.length < 2 ||
        !isDarkCanvasHistories.every((v) => v === isDarkCanvas)
      ) {
        return canvasImageData;
      }
    } finally {
      isDarkCanvasHistories.push(isDarkCanvas);
      if (isDarkCanvasHistories.length > 2) {
        isDarkCanvasHistories.shift();
      }
    }

    // brightness filter function
    const applyBrightnessFilter = (enabled) => {
      if (enabled) {
        const power =
          100 +
          Math.ceil((128 - Math.min(Math.round(vAverage), 127)) / 32) * 100;
        if (
          !this.#canvasCorrectionStatus.filter.enabled ||
          this.#canvasCorrectionStatus.filter.power !== power
        ) {
          this.video.style.filter = `brightness(${power}%)`;
          this.#canvasCorrectionStatus.filter = {
            enabled: true,
            power: power,
          };
          console.log(
            "Apply brightness filter [enabled]:",
            this.#canvasCorrectionStatus,
          );
        }
      } else if (!enabled && this.#canvasCorrectionStatus.filter.enabled) {
        this.video.style.filter = null;
        this.#canvasCorrectionStatus.filter = { enabled: false };
        console.log("Apply brightness filter [disabled]");
      }
    };

    if (isDarkCanvas) {
      if (this.#torchSupported && this.video.srcObject instanceof MediaStream) {
        const videoTrack = this.video.srcObject.getVideoTracks()[0];
        videoTrack
          ?.applyConstraints({
            // @ts-ignore
            torch: true,
          })
          .then(() => {
            this.#torched = true;
            console.log("torch enabled");
          })
          .catch((error) => {
            console.log("Error in correctDarkCanvas:", error);
            this.#torchSupported = false;
          });
      } else {
        applyBrightnessFilter(true);
      }
    } else {
      applyBrightnessFilter(false);
    }

    return canvasImageData;
  }
}

export class Html5QrCodeScanner extends QrCodeScanner {
  /** @type {Html5QrcodeShim} */
  html5Qrcode;

  /**
   * @param {onScanSuccessCallback} onScanSuccessHandler
   * @param {onScanErrorCallback} onScanErrorHandler
   * @param {onPauseStatusChangeCallback} onPauseStatusChangeHandler
   * @param {onVideoResizedCallback} onVideoResizedHandler
   */
  constructor(
    onScanSuccessHandler,
    onScanErrorHandler,
    onPauseStatusChangeHandler,
    onVideoResizedHandler,
  ) {
    super(
      onScanSuccessHandler,
      onScanErrorHandler,
      onPauseStatusChangeHandler,
      onVideoResizedHandler,
    );
    this.onReadQrcode = this.readQrcode;
  }

  async initBeforeMount() {
    this.html5Qrcode = new Html5QrcodeShim(
      [Html5QrcodeSupportedFormats.QR_CODE],
      false,
      false,
      new BaseLoggger(false),
    );
  }

  initAfterMount() {
    this.qrCodeCanvas = this.qrCodeCanvasElement.getContext("2d", {
      alpha: false,
      willReadFrequently: true,
    });
    this.qrCodeCanvas.imageSmoothingEnabled = false;
  }

  readQrcode() {
    this.html5Qrcode
      .decodeAsync(this.qrCodeCanvasElement)
      .then((result) => {
        this.shadedRegion.classList.add("flash");
        this.onScanSuccessHandler(result.text, true, CodeType.QRCODE);
      })
      .catch(() => {
        this.shadedRegion.classList.remove("flash");
      });
  }
}

export class ZBarQrCodeScanner extends QrCodeScanner {
  /** @type {import("@undecaf/zbar-wasm").ZBarScanner} */
  static scanner;

  /**
   * @param {onScanSuccessCallback} onScanSuccessHandler
   * @param {onScanErrorCallback} onScanErrorHandler
   * @param {onPauseStatusChangeCallback} onPauseStatusChangeHandler
   * @param {onVideoResizedCallback} onVideoResizedHandler
   */
  constructor(
    onScanSuccessHandler,
    onScanErrorHandler,
    onPauseStatusChangeHandler,
    onVideoResizedHandler,
  ) {
    super(
      onScanSuccessHandler,
      onScanErrorHandler,
      onPauseStatusChangeHandler,
      onVideoResizedHandler,
    );
    this.onReadQrcode = this.readQrcode;
  }

  async initBeforeMount() {
    try {
      if (!this.scanner) {
        setModuleArgs({
          locateFile: () => zbarWasmBinary,
        });

        this.scanner = await getDefaultScanner();
        this.scanner.setConfig(
          ZBarSymbolType.ZBAR_NONE,
          ZBarConfigType.ZBAR_CFG_ENABLE,
          0,
        );
        this.scanner.setConfig(
          ZBarSymbolType.ZBAR_CODABAR,
          ZBarConfigType.ZBAR_CFG_ENABLE,
          1,
        );
        this.scanner.setConfig(
          ZBarSymbolType.ZBAR_QRCODE,
          ZBarConfigType.ZBAR_CFG_ENABLE,
          1,
        );
      }
    } catch (error) {
      console.warn("ZBar initialization failed:", error);
      throw error;
    }
  }

  initAfterMount() {
    this.qrCodeCanvas = this.qrCodeCanvasElement.getContext("2d", {
      alpha: false,
      willReadFrequently: true,
    });
    this.qrCodeCanvas.imageSmoothingEnabled = false;
  }

  /**
   * @param {ImageData} imageData
   */
  readQrcode(imageData) {
    if (!imageData) {
      imageData = this.qrCodeCanvas.getImageData(
        0,
        0,
        this.qrCodeCanvasElement.width,
        this.qrCodeCanvasElement.height,
      );
    }

    scanImageData(imageData, this.scanner)
      .then((symbols) => {
        if (symbols?.length > 0) {
          this.shadedRegion.classList.add("flash");
          for (const symbol of symbols) {
            this.onScanSuccessHandler(
              symbol.decode(),
              true,
              symbol.type === ZBarSymbolType.ZBAR_QRCODE
                ? CodeType.QRCODE
                : CodeType.CODABAR,
            );
          }
        } else {
          this.shadedRegion.classList.remove("flash");
        }
      })
      .catch((error) => {
        console.warn(error);
      });
  }
}
