<script>
  import { getContext, onDestroy, tick } from "svelte";

  import HelpBase from "~/components/help/HelpBase.svelte";
  import HelpOfflineMode from "~/components/help/HelpOfflineMode.svelte";
  import {
    AddressTypeForMap,
    AppPageTypes,
    CONTEXT_KEY_APP,
    CONTEXT_KEY_USER,
    NotificationCategory,
    OfflineModeTypes,
    QrHomeTypes,
    receivedPushTypes,
  } from "~/libs/constants";
  import depotLocations from "~/libs/depotLocations";
  import notificationHistoryUtils from "~/libs/notificationHistoryUtils";
  import pageRouter from "~/libs/pageRouter";
  import {
    appPageStore,
    deliveryTarget,
    displayOfflineModeHelp,
    openedPushInfo,
    receivedPushInfo,
  } from "~/libs/stores";
  import List from "~/pages/List.svelte";
  import NativeAppLog from "~/pages/OtherMenu/NativeAppLog.svelte";
  import Notification from "~/pages/OtherMenu/Notification.svelte";
  import PasswordChange from "~/pages/OtherMenu/PasswordChange.svelte";
  import PresetOfDeliveryTimeFrame from "~/pages/OtherMenu/PresetOfDeliveryTimeFrame.svelte";
  import OtherMenu from "~/pages/OtherMenu.svelte";
  import OutForDeliveryWithQrCodeScan from "~/pages/OutForDeliveryWithQrCodeScan.svelte";
  import PickupAndSortWithQrCodeScan from "~/pages/PickupAndSortWithQrCodeScan.svelte";
  import QrHome from "~/pages/QrHome.svelte";
  import ReturnToEcWithQrCodeScan from "~/pages/ReturnToEcWithQrCodeScan.svelte";
  import TakebackPackageToDepotWithQrCodeScan from "~/pages/TakebackPackageToDepotWithQrCodeScan.svelte";
  import Update from "~/pages/Update.svelte";

  /** @type {import("~/libs/commonTypes").AppContext} */
  const appContext = getContext(CONTEXT_KEY_APP);

  /** @type {import("~/libs/commonTypes").UserContext} */
  const userContext = getContext(CONTEXT_KEY_USER);

  /** @type {List} 配達リストページのインスタンス */
  let listPage;
  /** @type {Update} 配達登録ページのインスタンス */
  let updatePage;
  /** 現在のAppPage @type {import("~/libs/commonTypes").AppPageStore} */
  let currentAppPage;
  /** AppPageStoreのUnsubscriber @type {import("svelte/store").Unsubscriber} */
  let appPageStoreUnsubscriber;

  /** オフラインモードヘルプを表示するか否か */
  let showsOfflineModeHelp = false;
  /** displayOfflineModeHelpのUnsubscriver @type {import("svelte/store").Unsubscriber} */
  const displayOfflineModeHelpUnsubscriber = displayOfflineModeHelp.subscribe(
    (display) => {
      if (display) {
        showsOfflineModeHelp = true;
      }
    },
  );

  const receivedPushInfoUnsubscriber = receivedPushInfo.subscribe(
    (pushInfo) => {
      if (pushInfo) {
        pushReceiveControl(pushInfo);
        receivedPushInfo.set(null);
      }
    },
  );

  const openedPushInfoUnsubscriber = openedPushInfo.subscribe((pushInfo) => {
    if (pushInfo) {
      moveToUpdatedPackage(pushInfo);
      openedPushInfo.set(null);
    }
  });

  // ページ初期化処理（async禁止）
  (() => {
    // オンラインモード切替失敗に伴うログインだった場合はオフラインモードで再表示
    if (appContext.failedToSwitchOnline) {
      console.log("オンラインモード切替失敗に伴うログイン");
      appContext.failedToSwitchOnline = false;
      appContext.store();
      pageRouter.moveToOfflineMode(appContext.offlineModeType);
      return;
    }

    // ログインが必要な場合はログイン画面に遷移
    if (userContext.needsLogin()) {
      console.log("非ログインまたは認証トークンの有効期限切れ");
      pageRouter.moveToLogin();
      return;
    }

    // 初期表示するページを設定
    if (userContext.hasDriverRole()) {
      currentAppPage = {
        type: AppPageTypes.AFTER_LOGIN,
        name: List.name,
      };
    } else {
      currentAppPage = {
        type: AppPageTypes.AFTER_LOGIN,
        name: QrHome.name,
        props: { qrHomeType: QrHomeTypes.PICKUP_AND_SORT },
      };
    }
    appPageStore.set(currentAppPage);

    // appPageStoreの変更をsubscribe
    appPageStoreUnsubscriber = appPageStore.subscribe((appPage) => {
      if (appPage && currentAppPage !== appPage) {
        currentAppPage = appPage;
      }
    });

    // Service Workerからのメッセージを処理するイベントハンドラを登録（重複排除のため一旦削除してから登録）
    navigator.serviceWorker.removeEventListener("message", onMessage);
    navigator.serviceWorker.addEventListener("message", onMessage);

    // 配送センター一覧を事前キャッシュ
    depotLocations.get().catch((error) => {
      console.warn(error); // 致命的エラーではないのでログだけ出力して続行 (use non-logger explicitly)
    });
  })();

  onDestroy(async () => {
    appPageStoreUnsubscriber?.();
    displayOfflineModeHelpUnsubscriber?.();
    receivedPushInfoUnsubscriber?.();
    openedPushInfoUnsubscriber?.();
  });

  /**
   * Webプッシュ(PWA向け)受信時の制御を行う。
   * @param {MessageEvent} event
   */
  function onMessage(event) {
    updateDeliveryListAndAddHistory(
      {
        trackingNumber: event.data.trackingNumber.replace(/-/g, ""),
        correctedReceiverAddress: event.data.correctedReceiverAddress,
        delivererInternalMessage: event.data.delivererInternalMessage,
        ecDelivererInternalMessage: event.data.ecDelivererInternalMessage,
        adjustedRedeliveryDatetime: event.data.adjustedRedeliveryDatetime,
      },
      {
        title: event.data.messageTitle,
        body: event.data.messageBody,
      },
    );
  }

  /**
   * リモートプッシュ(iOSネイティブアプリ向け)受信時の制御を行う。
   * @param {import("~/libs/commonTypes").PushNotificationInfo} pushInfo
   */
  function pushReceiveControl(pushInfo) {
    updateDeliveryListAndAddHistory(
      {
        trackingNumber: pushInfo.trackingNumber,
        correctedReceiverAddress: pushInfo.correctedReceiverAddress,
        delivererInternalMessage: pushInfo.delivererInternalMessage,
        ecDelivererInternalMessage: pushInfo.ecDelivererInternalMessage,
        adjustedRedeliveryDatetime: pushInfo.adjustedRedeliveryDatetime,
      },
      pushInfo.message,
    );
  }

  /**
   * リモートプッシュ、あるいはWebプッシュの受信時に、
   * localStrageの配達リスト更新と通知履歴への追加を行う。
   * @param {{
   *   trackingNumber: string,
   *   correctedReceiverAddress?: string,
   *   delivererInternalMessage?: string,
   *   ecDelivererInternalMessage?: string,
   *   adjustedRedeliveryDatetime?: import("~/libs/commonTypes").DateAndTimeFrame,
   * }} updateInfo
   * @param {{title: string, body: string}} [message]
   */
  function updateDeliveryListAndAddHistory(updateInfo, message) {
    // 通知履歴に登録
    if (message) {
      notificationHistoryUtils.deleteAndAddHistory(
        userContext.loginUser.username,
        NotificationCategory.PUSH,
        `${message.title}\n${message.body}`,
      );
    }

    if (userContext.deliveryList) {
      let newShippingList = userContext.deliveryList;
      for (let i = 0; i < newShippingList.length; i++) {
        if (newShippingList[i].trackingNumber == updateInfo.trackingNumber) {
          if (updateInfo.correctedReceiverAddress) {
            // 訂正住所の反映
            newShippingList[i].correctedReceiverAddress =
              updateInfo.correctedReceiverAddress;
            newShippingList[i].addressForMap = AddressTypeForMap.CORRECTED;
            newShippingList[i].receivedPushType =
              receivedPushTypes.CORRECTED_RECEIVER_ADDRESS;
          }
          if (updateInfo.delivererInternalMessage) {
            // 宅配事業者間通信欄の反映
            newShippingList[i].delivererInternalMessage =
              updateInfo.delivererInternalMessage;
            newShippingList[i].receivedPushType =
              receivedPushTypes.INTERNAL_MESSAGE;
          }
          if (updateInfo.ecDelivererInternalMessage) {
            // EC事業者通信欄の反映
            newShippingList[i].ecDelivererInternalMessage =
              updateInfo.ecDelivererInternalMessage;
            newShippingList[i].receivedPushType =
              receivedPushTypes.INTERNAL_MESSAGE;
          }
          if (updateInfo.adjustedRedeliveryDatetime) {
            // 再配達希望日時の反映
            if (newShippingList[i].redeliveryContext) {
              newShippingList[i].redeliveryContext.adjustedRedeliveryDatetime =
                updateInfo.adjustedRedeliveryDatetime;
            } else {
              newShippingList[i].redeliveryContext = {
                redeliveryDatetimeSpecMethod: null,
                timeFramePreset: null,
                redeliveryUnavailability: null,
                adjustedRedeliveryDatetime:
                  updateInfo.adjustedRedeliveryDatetime,
                notificationResend: false,
              };
            }
            delete newShippingList[i].specifiedPickupDatetime;
            newShippingList[i].receivedPushType =
              receivedPushTypes.ADJUSTED_REDELIVERY_DATETIME;
          }
        }
      }
      if (listPage) {
        // 配達リスト画面を表示中の場合は画面上の荷物情報を更新する
        listPage.reloadShippingList(newShippingList);
      } else if (
        updatePage &&
        $deliveryTarget.trackingNumber === updateInfo.trackingNumber
      ) {
        // 配達登録画面を表示中、かつ更新対象荷物が表示中の場合は画面上の荷物情報を更新する
        updatePage.updateDeliveryPackageInfo(
          updateInfo.correctedReceiverAddress,
          updateInfo.delivererInternalMessage,
          updateInfo.adjustedRedeliveryDatetime,
        );
      }
      userContext.store();
    }
  }

  /**
   * リモートプッシュをタップした場合に、配達リストの更新対象荷物を表示する。
   * @param {import("~/libs/commonTypes").PushNotificationInfo} pushInfo
   */
  async function moveToUpdatedPackage(pushInfo) {
    if (userContext.hasDriverRole) {
      // 宅配ドライバーでログイン中の場合しか通知は来ない想定だが、念のためチェック
      if (
        currentAppPage.name === Update.name ||
        currentAppPage.name === OutForDeliveryWithQrCodeScan.name ||
        currentAppPage.name === TakebackPackageToDepotWithQrCodeScan.name
      ) {
        // 配達登録画面・持出しQRスキャン・持戻りQRスキャン画面を表示中の場合は、画面遷移させない
        return;
      } else {
        // 上記以外の画面を表示中の場合は、配達リスト画面に遷移して該当荷物を表示
        pageRouter.moveToList();
        await tick();
        updateDeliveryListAndAddHistory({
          trackingNumber: pushInfo.trackingNumber,
          correctedReceiverAddress: pushInfo.correctedReceiverAddress,
          delivererInternalMessage: pushInfo.delivererInternalMessage,
          adjustedRedeliveryDatetime: pushInfo.adjustedRedeliveryDatetime,
        });
        setTimeout(() => {
          document
            .getElementById(pushInfo.trackingNumber)
            ?.scrollIntoView({ behavior: "smooth" });
        }, 100);
      }
    }
  }

  /**
   * オフラインモードへの切替えを行う。
   */
  function switchOfflineMode() {
    console.log(userContext.loginUser.roles);
    appContext.offlineMode = true;
    if (userContext.hasDriverRole) {
      appContext.offlineModeType = OfflineModeTypes.DELIVERED;
      pageRouter.moveToOfflineMode(OfflineModeTypes.DELIVERED);
    } else {
      appContext.offlineModeType = OfflineModeTypes.PICKUP_AND_SORT;
      pageRouter.moveToOfflineMode(OfflineModeTypes.PICKUP_AND_SORT);
    }
    appContext.store();
  }
</script>

{#if currentAppPage?.type === AppPageTypes.AFTER_LOGIN}
  {#if currentAppPage.name === List.name}
    <List bind:this={listPage} />
  {:else if currentAppPage.name === Update.name}
    <Update bind:this={updatePage} />
  {:else if currentAppPage.name === QrHome.name}
    {#key currentAppPage.props}
      <QrHome qrHomeType={currentAppPage.props.qrHomeType} />
    {/key}
  {:else if currentAppPage.name === OutForDeliveryWithQrCodeScan.name}
    <OutForDeliveryWithQrCodeScan />
  {:else if currentAppPage.name === TakebackPackageToDepotWithQrCodeScan.name}
    <TakebackPackageToDepotWithQrCodeScan />
  {:else if currentAppPage.name === PickupAndSortWithQrCodeScan.name}
    <PickupAndSortWithQrCodeScan />
  {:else if currentAppPage.name === ReturnToEcWithQrCodeScan.name}
    <ReturnToEcWithQrCodeScan
      selectReturnWork={currentAppPage.props.selectReturnWork}
    />
  {:else if currentAppPage.name === OtherMenu.name}
    <OtherMenu />
  {:else if currentAppPage.name === PasswordChange.name}
    <PasswordChange />
  {:else if currentAppPage.name === Notification.name}
    <Notification />
  {:else if currentAppPage.name === PresetOfDeliveryTimeFrame.name}
    <PresetOfDeliveryTimeFrame />
  {:else if currentAppPage.name === NativeAppLog.name}
    <NativeAppLog />
  {/if}
{/if}

<!-- ヘルプ表示 -->
{#if showsOfflineModeHelp}
  <HelpBase
    helpContents={HelpOfflineMode}
    clickConfirm={() => {
      showsOfflineModeHelp = false;
      displayOfflineModeHelp.set(false);
      switchOfflineMode();
    }}
    clickCancel={() => {
      showsOfflineModeHelp = false;
      displayOfflineModeHelp.set(false);
    }}
  />
{/if}
